/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useState } from 'react'
import Image from 'next/image'
import ShareWidgetModal from '@components/brandcomponents/ShareWidget'
import { onShareButton } from '@utils/videoUtils'
import { timeAgo } from '@utils/brandUtilsClientSide'
import styles from './videometadatacard.module.scss'
import { SHARE_FORWARD_WHITE_ICON } from './constants'

const VideoMetaDataCard = ({ releasedDate, title, shareLinkText, shareLink, views }) => {
  const [show, showModal] = useState(false)

  const handleShare = () => {
    onShareButton(
      {
        title: 'Title of your shared content',
        text: shareLinkText,
        url: shareLink,
      },
      showModal
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.details}>
          {timeAgo(releasedDate)}
          {views > 0 && (
            <>
              <span className={styles.dot}>•</span>
              {views} views
            </>
          )}
        </div>
      </div>
      <div className={styles.rightSide}>
        <div className={`${styles.oval} ${styles.customOval}`} onClick={handleShare}>
          <Image src={SHARE_FORWARD_WHITE_ICON} alt="Share" width="14" height="14" />
          <p className={styles.ovalText}>Share</p>
        </div>
      </div>
      <ShareWidgetModal showModal={showModal} show={show} shareLink={shareLink} shareLinkText={shareLinkText} />
    </div>
  )
}

export default VideoMetaDataCard
