'use client'

/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useEffect, useState } from 'react'
import { TRANDING, PLATFORM } from '@constants/toast'
import { getVideoShareUrl } from '@utils/brandUtilsClientSide'
import VideoPlayer from './VideoPlayer'
import VideoCard from './VideoCard'
import VideoMetaDataCard from './VideoMetaDataCard'
import styles from './videoplayercontainer.module.scss'
import { VIDEO_PLAYER_ID } from './constants'

const renderVideos = (videoList, selectedVideo, setSelectedVideo, setAutoPlay) =>
  videoList?.map((video: any) => (
    <VideoCard
      thumbnailSrc={video?.data?.thumbnails?.[video?.data?.thumbnails?.length - 1]?.link}
      title={video?.title}
      views={video?.activity_stats?.view?.compact_total}
      duration={video?.data?.duration?.length}
      setSelectedVideo={() => {
        setSelectedVideo(video)
        setAutoPlay(true)
      }}
      isMobile={false}
      selectedVideoId={selectedVideo?.id}
      id={video?.id}
    />
  ))
const VideoPlayerContainer = ({ videoList, brandName }) => {
  const [autoplay, setAutoPlay] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState<any>(videoList?.[0])

  useEffect(() => {
    setSelectedVideo(videoList?.[0])
  }, [])

  return videoList?.length > 0 ? (
    <div className={styles.videBackgroundContainer}>
      <div className={styles.absDiv} />
      <div id={VIDEO_PLAYER_ID} className={styles.videoPlayerContainer}>
        <h1 className={styles.heading}>
          <b>
            {TRANDING}
            {brandName}
          </b>
        </h1>
        <div className={styles.contentContainer}>
          <div className={styles.leftSide}>
            <div>
              {selectedVideo ? (
                <VideoPlayer videoDetails={selectedVideo} platform={PLATFORM} autoplay={autoplay} />
              ) : null}
            </div>
            <div>
              {selectedVideo && Object.keys(selectedVideo).length > 0 && (
                <VideoMetaDataCard
                  title={selectedVideo?.title}
                  releasedDate={selectedVideo?.published_on_formatted}
                  shareLink={getVideoShareUrl(selectedVideo?.id)}
                  shareLinkText={selectedVideo?.share_data?.text}
                  views={selectedVideo?.activity_stats?.view?.compact_total}
                />
              )}
            </div>
          </div>
          <div>
            <div className={styles.scrollableContainer}>
              {renderVideos(videoList, selectedVideo, setSelectedVideo, setAutoPlay)}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default React.memo(VideoPlayerContainer)
