/* eslint-disable @typescript-eslint/no-floating-promises */

'use client'

import React, { useEffect, useRef, useState, useMemo } from 'react'
import { TRANDING } from '@constants/toast'
import VideoCard from './VideoCard'
import styles from './videoplayermobile.module.scss'

const VideoPlayerMobileContainer = ({ videoList, brandName }) => {
  const sliderRef = useRef<any>(null)
  const [carouselIndicatorWidth, setCarouselIndicatorWidth] = useState(0)

  const handleScroll = () => {
    const scrollPosition = sliderRef?.current.scrollLeft
    const maxScrollPosition = sliderRef?.current.scrollWidth - sliderRef?.current?.clientWidth
    const scrollPercentage = (scrollPosition / maxScrollPosition) * 100
    setCarouselIndicatorWidth(scrollPercentage)
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener('scroll', handleScroll)
    }
    return () => {
      sliderRef?.current?.removeEventListener('scroll', handleScroll)
    }
  }, [videoList?.length])

  const selectedIndex = useMemo(() => {
    const index = Math.floor(carouselIndicatorWidth / Math.floor(100 / videoList?.length))
    if (index > videoList?.length - 1) {
      return videoList?.length - 1
    }
    return index
  }, [carouselIndicatorWidth, videoList])

  const setSelectedVideo = id => {
    window.location.href = `/videos/${id}`
  }

  return videoList?.length > 0 ? (
    <div className={styles.videoBackgroundContainer}>
      <div className={styles.absDiv} />
      <div className={styles.container}>
        <h1 className={styles.heading}>
          {TRANDING}
          {brandName}
        </h1>
        <div className={styles.videoSlider} ref={sliderRef}>
          {videoList?.map((video: any) => (
            <VideoCard
              thumbnailSrc={video?.data?.thumbnails?.[video?.data?.thumbnails?.length - 1]?.link}
              title={video?.title}
              isMobile
              id={video?.id}
              setSelectedVideo={setSelectedVideo}
              duration={video?.data?.duration?.length}
            />
          ))}
        </div>
        <div className={styles.underLineComponent}>
          {videoList?.map((_, index) => (
            <div key={index} className={`${styles.itemBottom} ${index === selectedIndex && styles.isSelected}`} />
          ))}
        </div>
      </div>
    </div>
  ) : null
}

export default VideoPlayerMobileContainer
